<mat-list class="px-3">
  <mat-list-item>
    <div class="w-100 text-center">
      <mat-icon class="md-48" (click)="switchTheme()">account_circle</mat-icon>
    </div>
  </mat-list-item>
  <mat-list-item class="text-center">
    <span matListItemTitle>{{fullName}}</span>
    <span matListItemLine>{{emailAddress}}</span>
  </mat-list-item>
  <mat-list-item>
    <a mat-button href="{{accountUrl}}" target="_blank" rel="norefferer" class="w-100">Manage Account</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <button mat-button matListItemLine (click)="logout()" class="w-100">Logout</button>
    <span matListItemLine (click)="checkUpdates()" class="w-100 d-inline-block text-center">{{versionText}}</span>
  </mat-list-item>
</mat-list>
<app-loading-bar *ngIf="updating"></app-loading-bar>
