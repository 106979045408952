import {Injectable, OnDestroy} from '@angular/core';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppPwaEventsService implements OnDestroy {

  private swSubscriptions: Subscription[] = [];
  private pwaEventSubject = new BehaviorSubject<PwaEvent>('sw_initialized');
  pwaEvent$: Observable<PwaEvent> = this.pwaEventSubject.asObservable();

  constructor(private swUpdate: SwUpdate) {
    this.swSubscriptions.push(this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === 'NO_NEW_VERSION_DETECTED') {
        this.pwaEventSubject.next('no_update_available');
      }
      if (event.type === 'VERSION_READY') {
        this.pwaEventSubject.next('update_available');
      }
      if (event.type === 'VERSION_INSTALLATION_FAILED') {
        this.pwaEventSubject.next('update_failed');
      }
    }));
    this.swSubscriptions.push(this.swUpdate.unrecoverable.subscribe(event => {
      console.error(event);
      this.pwaEventSubject.next('sw_error');
    }));
    if (this.isServiceWorkerEnabled()) {
      this.swSubscriptions.push(interval(60 * 60 * 1000).subscribe(() => this.checkForUpdate()));
    }
  }

  ngOnDestroy(): void {
    this.swSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public async checkForUpdate() {
    return this.swUpdate.checkForUpdate();
  }

  public isServiceWorkerEnabled(): boolean {
    return this.swUpdate.isEnabled;
  }
}

export type PwaEvent =
  | 'sw_initialized'
  | 'update_available'
  | 'update_failed'
  | 'no_update_available'
  | 'sw_error'
  | 'update_active';
