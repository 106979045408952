import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../../auth/authentication.service';
import {AppTabsService} from 'src/app/core/services/tabs.service';
import {AppMenuService, MenuEvent} from 'src/app/core/services/menu.service';
import {CoreService} from '../../services/core.service';
import {Practice} from 'src/app/modules/practice-repository/entities/practice';
import {PatientSearchComponent} from '../search/search.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})

export class AppTopNavComponent implements OnInit, OnDestroy {

  isNotHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => !result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isNotTablet$: Observable<any> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => {
        return !result.matches;
      }),
      shareReplay()
    );
  isTablet$: Observable<any> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  @ViewChild('practiceList') practiceList: ElementRef;
  @ViewChild(PatientSearchComponent) patientSearchBox: PatientSearchComponent;

  constructor(private breakpointObserver: BreakpointObserver, private tabService: AppTabsService, private authService: AuthenticationService, private coreService: CoreService) {
  }

  get selectedPractice(): Practice {
    return this.coreService.selectedPractice;
  }

  get hasTabs(): boolean {
    return this.tabService.hasTabs();
  }

  get hasPractices(): boolean {
    return this.coreService.hasPractices();
  }

  get homeLink(): string {
    return this.coreService.configuration.dashboardModuleEnabled ? '/dashboard' : '/';
  }

  get hasNotifications(): boolean {
    return this.coreService.hasNotifications();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  openSearch() {
    this.patientSearchBox.openSearch();
  }
}
