<div class="search-form material-search-override" [class.is-mobile]="isHandset$ || isTablet$ | async"
      [class.visible]="isVisible" [class.has-search-results]="hasResults || hasError"
      *appPermissionRequired="'Clinical.Patients.Read'"
      cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <mat-form-field class="search-form-full-width search-input" appearance="fill" subscriptSizing="dynamic" color="accent">
    <button mat-icon-button matPrefix (click)="closeSearch()" *ngIf="isHandset$ || isTablet$ | async">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-icon-button matPrefix *ngIf="isNotTablet$ && isNotHandset$ | async">
      <mat-icon>search</mat-icon>
    </button>
    <input type="text" class="" placeholder="Patients Search" #searchQuery matInput [formControl]="formControl"
           appSearchBox [searchObservable]="searchObservable" [minCharacters]="2" (results)="onSearchResults($event)" (error)="onError($event)" (searchStatusChange)="onSearchStatusChange($event)">
    <button mat-icon-button matSuffix (click)="cancelSearch()" *ngIf="searchQuery.value.length > 0">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayScrollStrategy]="scrollStrategy" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayHasBackdrop]="false" [cdkConnectedOverlayOpen]="resultsReady" [cdkConnectedOverlayPanelClass]="'search-overlay'" (overlayKeydown)="overlayClosed($event)">
    <div class="search-results mat-elevation-z3" [class.is-mobile]="isHandset$ || isTablet$ | async">
      <mat-table [dataSource]="dataSource" matSort class="">
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
          <mat-cell *matCellDef="let element" [style.background-color]="element.status.color">&nbsp;</mat-cell>
        </ng-container>
        <ng-container matColumnDef="profileId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> File No. </mat-header-cell>
          <mat-cell *matCellDef="let element" [innerHTML]="element.profileId.toString().padStart(4, '0') | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" [innerHTML]="element.firstName + ' ' + element.middleNames | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="surname">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Surname </mat-header-cell>
          <mat-cell *matCellDef="let element" [innerHTML]="element.surname | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="nickname">
          <mat-header-cell *matHeaderCellDef [hidden]="isHandset$ | async" mat-sort-header> Nickname </mat-header-cell>
          <mat-cell *matCellDef="let element" [hidden]="isHandset$ | async" [innerHTML]="element.nickname | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="tel">
          <mat-header-cell *matHeaderCellDef [hidden]="isHandset$ | async" mat-sort-header> Tel No </mat-header-cell>
          <mat-cell *matCellDef="let element" [hidden]="isHandset$ | async" [innerHTML]="element.tel | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="cell">
          <mat-header-cell *matHeaderCellDef [hidden]="isHandset$ || isTablet$ | async" mat-sort-header> Cell No </mat-header-cell>
          <mat-cell *matCellDef="let element" [hidden]="isHandset$ || isTablet$ | async" [innerHTML]="element.cell | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="identityNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="isHandset$ || isTablet$ | async"> ID No </mat-header-cell>
          <mat-cell *matCellDef="let element" [hidden]="isHandset$ || isTablet$ | async" [innerHTML]="element.identityNumber | textHighlight: searchQuery.value"></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></mat-header-row>>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;" (click)="rowSelected(row)"></mat-row>
        <div *matNoDataRow class="mat-row p-3">
          <p class="text-center">No results could be found to match your query</p>
          <button mat-flat-button color="primary" class="w-100" (click)="addNewPatient()">Click here to add a new patient to your profile</button>
        </div>
      </mat-table>
      <div class="p-3" *ngIf="hasError">
        <span>{{error}}</span>
      </div>
      <div class="text-center" *ngIf="hasMoreResults && !showAll">
        <button mat-button color="primary" (click)="toggleAllResults()">Show more</button>
      </div>
      <div class="text-center" *ngIf="showAll">
        <button mat-button color="primary" (click)="toggleAllResults()">Hide more</button>
      </div>
    </div>
  </ng-template>
</div>
