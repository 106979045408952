<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a mat-icon-button [routerLink]="homeLink" [routerLinkActive]="'false'">
      <mat-icon>home</mat-icon>
    </a>
    <practice-switcher></practice-switcher>
    <span class="toolbar-spacer"></span>
    <app-patient-search *ngIf="hasPractices"></app-patient-search>
    <span class="toolbar-spacer"></span>

    <button mat-icon-button *ngIf="hasPractices && (isHandset$ || isTablet$ | async)" (click)="openSearch()">
      <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="notificationMenu">
      <mat-icon *ngIf="!hasNotifications">notifications_none</mat-icon>
      <mat-icon *ngIf="hasNotifications">notifications</mat-icon>
    </button>
    <mat-menu #notificationMenu="matMenu">
      <notifications-menu></notifications-menu>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu" class="mat-menu-full-width">
      <app-menu></app-menu>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
      <user-menu></user-menu>
    </mat-menu>
  </mat-toolbar-row>
  <mat-toolbar-row style="height: 48px; padding: 0;" *ngIf="hasTabs">
    <ui-tab-bar></ui-tab-bar>
  </mat-toolbar-row>
</mat-toolbar>
