export abstract class Permissions {
  static ClinicalAppointmentsRead = 'Clinical.Appointments.Read';
  static ClinicalAppointmentsWrite = 'Clinical.Appointments.Write';
  static ClinicalAppointmentsDelete = 'Clinical.Appointments.Delete';
  static ClinicalAppointmentTypesRead = 'Clinical.AppointmentTypes.Read';
  static ClinicalAppointmentTypesWrite = 'Clinical.AppointmentTypes.Write';
  static ClinicalAppointmentTypesDelete = 'Clinical.AppointmentTypes.Delete';
  static ClinicalTreatmentPlansRead = 'Clinical.TreatmentPlans.Read';
  static ClinicalTreatmentPlansWrite = 'Clinical.TreatmentPlans.Write';
  static ClinicalTreatmentPlansDelete = 'Clinical.TreatmentPlans.Delete';
  static ClinicalCommentsRead = 'Clinical.Comments.Read';
  static ClinicalCommentsWrite = 'Clinical.Comments.Write';
  static ClinicalCommentsDelete = 'Clinical.Comments.Delete';
  static ClinicalCommentTemplatesRead = 'Clinical.CommentTemplates.Read';
  static ClinicalCommentTemplatesWrite = 'Clinical.CommentTemplates.Write';
  static ClinicalCommentTemplatesDelete = 'Clinical.CommentTemplates.Delete';
  static ClinicalCommentTypesRead = 'Clinical.CommentTypes.Read';
  static ClinicalCommentTypesWrite = 'Clinical.CommentTypes.Write';
  static ClinicalCommentTypesDelete = 'Clinical.CommentTypes.Delete';
  static ClinicalDoctorsRead = 'Clinical.Doctors.Read';
  static ClinicalDoctorsWrite = 'Clinical.Doctors.Write';
  static ClinicalDoctorsDelete = 'Clinical.Doctors.Delete';
  static ClinicalDocumentsRead = 'Clinical.Documents.Read';
  static ClinicalDocumentsWrite = 'Clinical.Documents.Write';
  static ClinicalDocumentsDelete = 'Clinical.Documents.Delete';
  static ClinicalEmailsRead = 'Clinical.Emails.Read';
  static ClinicalEmailsWrite = 'Clinical.Emails.Write';
  static ClinicalEmailsDelete = 'Clinical.Emails.Delete';
  static ClinicalEmailTemplatesRead = 'Clinical.EmailTemplates.Read';
  static ClinicalEmailTemplatesWrite = 'Clinical.EmailTemplates.Write';
  static ClinicalEmailTemplatesDelete = 'Clinical.EmailTemplates.Delete';
  static ClinicalMedicationRead = 'Clinical.Medication.Read';
  static ClinicalMedicationWrite = 'Clinical.Medication.Write';
  static ClinicalMedicationDelete = 'Clinical.Medication.Delete';
  static ClinicalMessagesRead = 'Clinical.Messages.Read';
  static ClinicalMessagesWrite = 'Clinical.Messages.Write';
  static ClinicalMessagesDelete = 'Clinical.Messages.Delete';
  static ClinicalPatientsRead = 'Clinical.Patients.Read';
  static ClinicalPatientsWrite = 'Clinical.Patients.Write';
  static ClinicalPatientsDelete = 'Clinical.Patients.Delete';
  static ClinicalPracticesRead = 'Clinical.Practices.Read';
  static ClinicalPracticesWrite = 'Clinical.Practices.Write';
  static ClinicalPracticesDelete = 'Clinical.Practices.Delete';
  static ClinicalProblemListingRead = 'Clinical.ProblemListing.Read';
  static ClinicalProblemListingWrite = 'Clinical.ProblemListing.Write';
  static ClinicalProblemListingDelete = 'Clinical.ProblemListing.Delete';
  static ClinicalProceduresRead = 'Clinical.Procedures.Read';
  static ClinicalProceduresWrite = 'Clinical.Procedures.Write';
  static ClinicalProceduresDelete = 'Clinical.Procedures.Delete';
  static ClinicalReportsRead = 'Clinical.Reports.Read';
  static ClinicalReportsWrite = 'Clinical.Reports.Write';
  static ClinicalReportsDelete = 'Clinical.Reports.Delete';
  static ClinicalReportTemplatesRead = 'Clinical.ReportTemplates.Read';
  static ClinicalReportTemplatesWrite = 'Clinical.ReportTemplates.Write';
  static ClinicalReportTemplatesDelete = 'Clinical.ReportTemplates.Delete';
  static ClinicalSystemTemplatesRead = 'Clinical.SystemTemplates.Read';
  static ClinicalSystemTemplatesWrite = 'Clinical.SystemTemplates.Write';
  static ClinicalSystemTemplatesDelete = 'Clinical.SystemTemplates.Delete';
  static ClinicalScriptsRead = 'Clinical.Scripts.Read';
  static ClinicalScriptsWrite = 'Clinical.Scripts.Write';
  static ClinicalScriptsDelete = 'Clinical.Scripts.Delete';
  static ClinicalTreatmentPlansListingRead = 'Clinical.TreatmentPlansListing.Read';
  static ClinicalTreatmentPlansListingWrite = 'Clinical.TreatmentPlansListing.Write';
  static ClinicalTreatmentPlansListingDelete = 'Clinical.TreatmentPlansListing.Delete';
  static ClinicalUploadsRead = 'Clinical.Uploads.Read';
  static ClinicalUploadsWrite = 'Clinical.Uploads.Write';
  static ClinicalUploadsDelete = 'Clinical.Uploads.Delete';
  static ReportBuilderReportTemplatesRead = 'ReportBuilder.ReportTemplates.Read';
  static ReportBuilderReportTemplatesWrite = 'ReportBuilder.ReportTemplates.Write';
  static ReportBuilderReportTemplatesDelete = 'ReportBuilder.ReportTemplates.Delete';
  static ReportBuilderSystemTemplatesRead = 'ReportBuilder.SystemTemplates.Read';
  static ReportBuilderSystemTemplatesWrite = 'ReportBuilder.SystemTemplates.Write';
  static ReportBuilderSystemTemplatesDelete = 'ReportBuilder.SystemTemplates.Delete';
  static ClinicalDraftsRead = 'Clinical.Drafts.Read';
  static ClinicalDraftsWrite = 'Clinical.Drafts.Write';
  static ClinicalDraftsDelete = 'Clinical.Drafts.Delete';
  static ClinicalCustomScriptsRead = 'Clinical.CustomScripts.Read';
  static ClinicalCustomScriptsWrite = 'Clinical.CustomScripts.Write';
  static ClinicalTranscribeRead = 'Clinical.Transcribe.Read';
  static ClinicalTranscribeWrite = 'Clinical.Transcribe.Write';
  static ClinicalBillingRead = 'Clinical.Billing.Read';
  static ClinicalBillingWrite = 'Clinical.Billing.Write';
  static ClinicalBillingDelete = 'Clinical.Billing.Delete';
  static Superuser = 'Superuser';
}
