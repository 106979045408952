import {Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {environment} from 'src/environments/environment';
import {AppPwaEventsService} from '../../services/app-pwa-events.service';
import {DOCUMENT} from '@angular/common';
import {CoreService} from '../../services/core.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  updating = false;

  constructor(private authService: AuthenticationService, private pwaService: AppPwaEventsService, @Inject(DOCUMENT) private doc: Document, private coreService: CoreService) {
  }

  get accountUrl(): string {
    return this.authService.getAccountUrl();
  }

  get fullName(): string {
    return this.authService.getFullName();
  }

  get emailAddress(): string {
    return this.authService.getEmailAddress();
  }

  get userName(): string {
    return this.authService.getUserName();
  }

  get versionText(): string {
    return 'version: ' + environment.version;
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }

  checkUpdates() {
    console.log('check for updates');
    if (this.pwaService.isServiceWorkerEnabled()) {
      this.updating = true;
      this.pwaService.checkForUpdate()
        .then((result) => {
          if (!result) {
            this.coreService.showToast('No updates available');
          }
        }).catch((error) => {
          console.error(error);
          this.coreService.showErrorToast('Error checking for updates');
        }).finally(() => {
          this.updating = false;
        });
    }
  }

  switchTheme() {
      const currentDataTag = this.doc.documentElement.classList;
      if (currentDataTag.length === 0) {
        this.doc.documentElement.classList.add('theme-default');
      } else if (this.doc.documentElement.classList.contains('theme-default')) {
        this.doc.documentElement.classList.add('theme-light');
        this.doc.documentElement.classList.remove('theme-default');
      } else if (this.doc.documentElement.classList.contains('theme-light')) {
        this.doc.documentElement.classList.add('theme-dark');
        this.doc.documentElement.classList.remove('theme-light');
      } else {
        this.doc.documentElement.classList.remove('theme-dark');
      }
  }
}
