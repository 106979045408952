import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoreService} from '../../services/core.service';
import {Observable} from 'rxjs';
import {QueryPatient} from 'src/app/modules/patient-repository/entities/patient';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PatientService} from 'src/app/modules/patients/services/patient.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {UntypedFormControl} from '@angular/forms';
import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-patient-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class PatientSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  formControl = new UntypedFormControl();
  error = '';
  searching = false;
  dataSource = new MatTableDataSource<QueryPatient>(null);
  searchResults: QueryPatient[] = [];
  isVisible = false;
  showAll = false;
  displayedColumns: string[] = ['status', 'profileId', 'surname', 'firstName', 'nickname', 'cell', 'tel', 'identityNumber'];
  searchObservable = this.patientService.searchPatients;
  @ViewChild(MatSort) sort: MatSort;
  scrollStrategy: ScrollStrategy = this.scrollStrategyOptions.block();


  isTablet$: Observable<any> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  isNotTablet$: Observable<any> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => !result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isNotHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => !result.matches),
      shareReplay()
    );

  constructor(
    private scrollStrategyOptions: ScrollStrategyOptions,
    private patientService: PatientService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  get hasMoreResults(): boolean {
    return this.searchResults?.length > this.dataSource?.data?.length;
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasResults(): boolean {
    return this.resultsReady && this.dataSource.data.length > 0;
  }

  get resultsReady(): boolean {
    return !this.searching && this.dataSource.data !== null && this.dataSource.data.length >= 0 && this.formControl?.value?.length > 0;
  }

  get filterByHighestQueryWeight(): QueryPatient[] {
    const bestRanking = [...new Set(this.searchResults.map((patient) => patient.queryWeight))][0];
    const result: QueryPatient[] = [];
    return this.searchResults.filter((patient) => patient.queryWeight === bestRanking);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
  }

  rowSelected(option: QueryPatient) {
    this.closeSearch();
    this.patientService.openTabWithQueryResult(option);
  }

  onSearchResults(results: QueryPatient[]) {
    if (results === null) {
      results = [];
    }
    this.searchResults = results;
    this.updateDataSource();
    this.error = '';
  }

  onSearchStatusChange(isSearching: boolean) {
    this.searching = isSearching;
  }

  onError(errorMessage: string) {
    this.error = errorMessage;
  }

  openSearch() {
    this.isVisible = true;
  }

  closeSearch() {
    this.isVisible = false;
    this.cancelSearch();
  }

  cancelSearch() {
    this.formControl.setValue('');
    this.dataSource = new MatTableDataSource<QueryPatient>(null);
    this.searchResults = [];
    this.showAll = false;
    this.error = '';
  }

  toggleAllResults() {
    this.showAll = !this.showAll;
    this.updateDataSource();
  }

  addNewPatient() {
    this.closeSearch();
    this.router.navigateByUrl('/patients/new');
  }

  overlayClosed(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeSearch();
    }
  }

  private updateDataSource() {
    const data = this.showAll ? this.searchResults : this.filterByHighestQueryWeight;
    this.dataSource.data = [...data];
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    });
  }
}
