<div [style.width]=screenWidth>
<nav mat-tab-nav-bar backgroundColor="primary" [tabPanel]="tabPanel">
  <a mat-tab-link *ngFor="let tab of tabs; let i=index" routerLink="{{tab.route}}" [active]="isTabActive(tab)">
    {{tab.title.split('-').join(" ")}}
    <button mat-icon-button aria-label="Close tab" (click)="closeTab($event, i)">
      <mat-icon >close</mat-icon>
    </button>
  </a>
</nav>
</div>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
