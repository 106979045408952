import {Injectable, OnDestroy, Renderer2, RendererFactory2} from '@angular/core';
import {LocalStorageService} from '../../modules/storage/local-storage.service';
import {interval, Subject, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService implements OnDestroy {

  private timerSubscription: Subscription;
  private timeout = 15 * 60 * 1000;
  private debounceTimeout = 1000;
  private eventsSubject: Subject<boolean> = new Subject();
  private debounceTimer: any = null;
  private renderer: Renderer2;
  private mousemoveListener = null;
  private scrollListener = null;
  private keydownListener = null;
  private clickListener = null;
  private visibilityChangedListener = null;
  events$ = this.eventsSubject.asObservable();
  private get storageKey() {
    return '_xmedical_idle_timeout_' + (environment.production ? 'prod' : 'debug');
  }

  constructor(private localStorage: LocalStorageService, private rendererFactory2: RendererFactory2) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  start(timeout: number) {
    this.timeout = timeout;
    this.updateExpiredTime();
    this.bindToEvents();
    this.startTimer();
  }

  stop() {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = null;
    this.unbindFromEvents();
  }

  get isRunning(): boolean {
    return !!this.timerSubscription;
  }

  ngOnDestroy(): void {
    this.stop();
  }

  private bindToEvents() {
    this.mousemoveListener = this.renderer.listen('document', 'mousemove', _ => this.updateExpiredTime());
    this.scrollListener = this.renderer.listen('document', 'scroll', _ => this.updateExpiredTime());
    this.keydownListener = this.renderer.listen('document', 'keydown', _ => this.updateExpiredTime());
    this.clickListener = this.renderer.listen('document', 'click', _ => this.updateExpiredTime());
    this.visibilityChangedListener = this.renderer.listen('document', 'visibilitychange', _ => this.onVisibilityChanged());
  }

  private unbindFromEvents() {
    if (this.mousemoveListener) { this.mousemoveListener(); }
    if (this.scrollListener) { this.scrollListener(); }
    if (this.keydownListener) { this.keydownListener(); }
    if (this.clickListener) { this.clickListener(); }
    if (this.visibilityChangedListener) { this.visibilityChangedListener(); }
  }

  private onVisibilityChanged(event = null) {
    if (document.visibilityState === 'visible') {
      this.start(this.timeout);
    } else {
      this.stop();
    }
  }
  private updateExpiredTime(event = null) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.localStorage.setItem(this.storageKey, Date.now() + this.timeout);
    }, this.debounceTimeout);
  }

  private startTimer() {
    this.updateExpiredTime();
    this.timerSubscription = interval(5000).subscribe(() => {
      const expiredTime = parseInt(this.localStorage.getItem(this.storageKey, 0), 10);
      if (expiredTime < Date.now()) {
        this.eventsSubject.next();
      }
    });
  }
}
