<button mat-flat-button color="primary" [matMenuTriggerFor]="practiceSelectMenu" *ngIf="enabled && isNotHandset$ | async">
  {{ selectedPracticeName }}
  <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
</button>
<button mat-flat-button color="primary" *ngIf="!enabled && isNotHandset$ | async">
  {{ selectedPracticeName }}
</button>
<button mat-flat-button color="primary" class="truncate" [matMenuTriggerFor]="practiceSelectMenu" *ngIf="enabled && isHandset$ | async">
  {{ selectedPracticeName | stringInitialFormatter }}
  <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
</button>
<button mat-flat-button color="primary" class="truncate" *ngIf="!enabled && isHandset$ | async">
  {{ selectedPracticeName | stringInitialFormatter }}
</button>
<mat-menu #practiceSelectMenu="matMenu">
  <mat-action-list>
    @for (practice of practices; track practice.id) {
      <button mat-list-item (confirm)="selectPractice(practice.id)" [class.active]="selectedPractice.id === practice.id"
              confirmTitle="Confirm Practice Selection"
              confirmMessage="Are you sure you wish to switch from {{ selectedPracticeName }} to {{ practice.name }}"
              appConfirmationDialog>
        <svg matListItemAvatar class="practice-icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <text x="5" y="15" font-family="Arial" font-size="15" fill="Black">{{ practice.name | slice: 0: 1 }}</text>
        </svg>
        {{practice.name}}
      </button>
    }
  </mat-action-list>

</mat-menu>
