<div class="parent-holder">
    <div class="content-block bg-theme-dark d-flex flex-column justify-content-center banner">
        <div class="container mx-auto pt-5 pb-6">
            <div class="row">
                <div class="col-sm-12 col-md-12 banner-text text-sm-center text-light">
                    <h1 class="display-4">An Application Error Occurred</h1>
                    <p class="text-light">Unfortunately the application could not continue operating.<br>This has been logged and support has been notified.<br>We apologise for the inconvenience and we will be back shortly.</p>
                    <a class="btn btn-outline-light" [routerLink]="['/']">Go Home</a>
                </div>
            </div>
        </div>
    </div>
</div>